<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput size="" v-model="booking_no" placeholder="Booking No."/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="bookingTypeList.data"
            :reduce="item => item.id"
            v-model="booking_type_id"
            placeholder="Booking Type"
          > 
          </v-select> 
        </CCol>
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            placeholder="Customer"
          > 
          </v-select> 
        </CCol> 
    
          <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="originList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select> 
        </CCol>
       
      <!-- </CRow>
      <CRow> -->
       <CCol lg="3">
          <v-select 
            label="vendor_name" 
            :options="vendorList.data"
            :reduce="item => item.id"
            v-model="vendor_id"
            placeholder="Trucker"
          > 
          </v-select> 
        </CCol>
         <CCol lg="3">
          <CInput size="" v-model="client_ref_no" placeholder="Client Ref No."/>
        </CCol>
        
        <CCol lg="3">
          <CInput size="" v-model="plate_no" placeholder="Plate No."/>
        </CCol>
       
        <CCol lg="3">
          <v-select 
            :disabled="depotid"
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select> 
        </CCol>

         <CCol lg="3">
          <Datepicker v-model="delivery_date_from" input-class="form-control" placeholder="Delivery date from" />
          
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery date to" />
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="commodityTypeList.data"
            :reduce="item => item.id"
            v-model="commodity_id"
            placeholder="Commodity Type"
          > 
          </v-select> 
        </CCol>
        <CCol lg="3" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
       
       </CRow>
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import moment from 'moment';
  import Datepicker from 'vuejs-datepicker';
  import Swal from 'sweetalert2';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            client_ref_no: "",
            booking_no: "",
            booking_type_id: "",
            commodity_id: "",
            customer_id: "",
            asset_type: "",
            vendor_id: "",
            origin_id: "",
            depot_id : "",
            delivery_date_to: new Date(),
            delivery_date_from: moment(new Date()).subtract(1, 'month')._d, 
            plate_no: "",
            bookingTypeList: {
            	data: []
            },
             commodityTypeList: {
            	data: []
            },
      			customerList: {
      				data: []
      			},
            originList: {
              data: []
            },
             depotList : {
              data : []
            },
             vendorList: {
              data: []
            },
          }
        },
      mounted() {
        this.getBookingType();
         this.getCommodityType();
        this.getCustomer();
        this.getDepot()
        this.getOriginList();
        this.getVendor();
      },
       watch: {
        depotid(val) {
          if(val) this.depot_id = val;
        }
      },
       props : ['depotid'],
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            client_ref_no: this.client_ref_no,
            booking_no: this.booking_no,
            booking_type_id:  this.booking_type_id,
            vendor_id: this.vendor_id,
            customer_id:  this.customer_id,
            asset_type:  this.asset_type,
            origin_id:  this.origin_id,
            delivery_date_to:  this.delivery_date_to,
            delivery_date_from:  this.delivery_date_from,
            plate_no: this.plate_no,
            depot_id : this.depot_id,
            commodity_id:  this.commodity_id,
          }
          if(moment(this.delivery_date_to).diff(moment(this.delivery_date_from), 'months', true) > 3){
            Swal.fire({
              title: 'Note!',
              text: "Delivery range should not exceed 3 months.",
              icon: 'warning', 
            })
          }
          else{
            this.$emit("depot-search-query", data)
          }
        },
      
        clearSearch() {
          this.client_ref_no = ""
          this.booking_no = ""
          this.booking_type_id = ""
          this.plate_no = ""
          this.customer_id = ""
          this.depot_id = ""
          this.vendor_id = ""
          this.asset_type = ""
          this.origin_id = ""
          this.commodity_id = ""
          this.delivery_date_to = new Date()
          this.delivery_date_from = moment(new Date()).subtract(1, 'month')._d
          const data = {
            client_ref_no: this.client_ref_no,
            booking_no: this.booking_no,
            booking_type_id:  this.booking_type_id,
            customer_id:  this.customer_id,
            asset_type:  this.asset_type,
            origin_id:  this.origin_id,
            vendor_id: this.vendor_id,
            delivery_date_to: new Date(),
            commodity_id: this.commodity_id,
            delivery_date_from: moment(new Date()).subtract(1, 'month')._d,
            plate_no: this.plate_no,
            commodity_id:  this.commodity_id,
          }
          this.$emit("depot-search-query", data)
        },

        getBookingType(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'booking_type'
            }
       		   })
	    	.then(response => {
	    		this.bookingTypeList = response.data; 
	    	})

	    },
       getCommodityType(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'commodity_type'
            }
       		   })
	    	.then(response => {
	    		this.commodityTypeList = response.data; 
	    	})

	    },
        
         getVendor(){

          axios.get(config.api_path+'/reference/vendors-list')
          .then(response => {
            this.vendorList = response.data; 
          })

        },
        
         getCustomer(){

            axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
            .then(response => {
              this.customerList = response.data;
              
            })

	    },

          getDepot(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
       		   })
	    	.then(response => {
	    		this.depotList = response.data; 
	    	})

	    },
        
        getOriginList(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'origin'
            }
       		   })
	    	.then(response => {
	    		this.originList = response.data; 
	    	})

	    },

    
      }
    }
</script>

<style scoped>

</style>
