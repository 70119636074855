<template>


<div class="modal fade" id="complete-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Update status to COMPLETED</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	  <form @submit.prevent="approve">
      <div class="modal-body" v-if="details">
		<form>
	<CRow>		
		<div class="alert alert-danger ml-3 mr-3" role="alert">
			<CCol lg="12">
                    <h6><i class="fa fa-warning"></i> IMPORTANT!</h6>
                    <small class="mb-0">Please review the associated rates for this shipment before pressing <b>'Submit'</b> button.
				Make sure to check the farthest destination of the shipment.</small>
                  
                   
			</CCol>
		</div>	
		
					
	</CRow>

	<fieldset class="fieldset full-line" >
						<legend>Booking Information</legend>
    	
				<h5 class="mb-2">
        			Booking #: {{details.booking_no}}
        		</h5> 
				<CRow class="mb-2">
        		<CCol lg="6">
						<small class="mr-2">Delivery Date</small>
						<CInput
		                  disabled
		                  :value="moment.unix(details.delivery_date).format('MMMM DD, YYYY')" 
		                  type="text"
						  class="mb-2"
		                />
				</CCol>	
        		
				<CCol lg="6" v-if="details.vehicle_type">
						<small class="mr-2">Vehicle Type</small>
						<CInput
		                  disabled
		                  :value="details.vehicle_type.setting_name" 
		                  type="text"
						  class="mb-2"
		                />
				</CCol>		
				
        	</CRow>
    		
        	<hr/>
			
        	<CRow>
        		<CCol lg="12">
        			<div class="form-group"> 
						<label class="mb-1">Completed Date & Time <span class="text-danger">*</span></label>
						<Datetime format="MM/DD/YYYY H:i" v-model="bookingParams.completed_datetime" required></Datetime>
					</div>
        			
        		</CCol> 
        	</CRow> 
        	<CRow>
        		<CCol lg="12">
	        		<div class="form-group"> 
						<label class="mb-1">Customer <span class="text-danger">*</span></label>
						<v-select 
							label="customer_name" 
							:options="customerList.data"
							:reduce="item => item.id"
							v-model="bookingParams.customer_id"
							placeholder="=Select-" 
							@input="computeclientrate"
							:disabled="disable_when(['dispatcher','pod','finance staff','fleet staff','fleet manager','pod + dispatcher','rw hr staff'])"
										
						> 
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.customer_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>
					</div>
				</CCol>
        	</CRow> 
        	<CRow>
        		<CCol lg="6">
	        		<div class="form-group"> 
						<label class="mb-1">Booking Type <span class="text-danger">*</span></label>
						<v-select 
							label="setting_name" 
							:options="bookingTypeList.data"
							:reduce="item => item.id"
							v-model="bookingParams.booking_type_id"
							@input="computeclientrate"
							placeholder="-Select-" 
							:disabled="disable_when(['dispatcher','pod','finance staff','fleet staff','fleet manager','pod + dispatcher','rw hr staff'])"
						> 
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.booking_type_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>
					</div>
				</CCol>
				<CCol lg="6">
	        		<div class="form-group"> 
						<label class="mb-1">Depot <span class="text-danger">*</span></label>
						<v-select 
							label="setting_name" 
							:options="depotList.data"
							@input="computeclientrate"
							:reduce="item => item.id"
							v-model="bookingParams.depot_id"
							placeholder="-Select-" 
						> 
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.depot_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>
					</div>
				</CCol>
        	</CRow> 
        	<CRow>
        		
				<CCol lg="6">
	        		<div class="form-group"> 
						<label class="mb-1">Vehicle No. <span class="text-danger">*</span></label>
						<v-select 
							label="plate_no"
							:options="vehicleList.data"
							:value="bookingParams.vehicle_info"
							placeholder="-Select-"  
							id="vehicle_no"
							@input="vehicleChange"
						>
								<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.vehicle_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template> 
						</v-select>
						
					</div>
				</CCol>
				<CCol lg="6">
				<div class="form-group"> 
					<label class="mb-1">Truck Type<span class="text-danger"> *</span></label>
					<v-select 
						taggable
						@input="truckTypeChange"
						label="setting_name" 
						:options="vehicleTypeList.data"
						:value="bookingParams.vehicle_type_label"
						placeholder="-Select-" 
					>
						<template #search="{attributes, events}">
							<input
								class="vs__search"
								:required="!bookingParams.vehicle_type_label"
								v-bind="attributes"
								v-on="events"
							/>
						</template>
					</v-select>
				</div>
				</CCol>
				<CCol lg="2">
					
				</CCol>
				
        	</CRow>
			<CRow>
				<CCol lg="6">
	        		<div class="form-group"> 
						<label class="mb-1">Commodity Type <span class="text-danger">*</span></label>
						<v-select 
							label="setting_name" 
							:options="commodityTypeList.data"
							:reduce="item => item.id"
							v-model="bookingParams.commodity_id"
							@input="computeclientrate"
							placeholder="-Select-" 
							:disabled="disable_when(['dispatcher','pod','finance staff','fleet staff','fleet manager','rw hr staff'])"
						> 
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.commodity_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>
					</div>
				</CCol>
			</CRow>
   </fieldset>
        	
		 <fieldset class="fieldset full-line">
						<legend>Client Cost</legend>
        	<CRow>
        		<CCol lg="12">
        			<div class="form-group"> 
						<label class="mb-1">Origin <span class="text-danger">*</span></label>
						<v-select 
							label="setting_name" 
							:options="originList.data"
							:reduce="item => item.id"
							v-model="bookingParams.origin_id"
							@input="computeclientrate"
							placeholder="-Select-" 
							:disabled="disable_when(['dispatcher','pod','finance staff','fleet staff','fleet manager','pod + dispatcher','rw hr staff'])"
						> 
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.origin_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>
					</div>
        		</CCol> 
        		<CCol lg="12">
        			<div class="form-group"> 
						<label class="mb-1">Farthest destination <span class="text-danger">*</span></label>
						<v-select label="destination" 
							:filterable="false" 
							:options="options" 
							@option:clear="onClear"
							@search="onSearch" 
							@input="destinationChanged"
							v-model="bookingParams.destination_label"
							>
								<template slot="no-options">
								Type to search destination. (minimum 3 characters)
								</template>
								<template slot="option" slot-scope="option">
								<div class="d-center">
									{{ option.destination }}
									</div>
								</template>
								<template slot="selected-option" slot-scope="option">
								<div class="selected d-center">
									{{ option.destination }}
								</div>
								</template>
								<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.destination_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
							</v-select>
						<!-- <v-select 
							label="setting_name" 
							:options="destinationList.data"
							v-model="bookingParams.destination_label"
							placeholder="-Select-" 
							@input="destinationChanged"
							
						> 
						 	
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.destination_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select> -->
					</div>
        		</CCol> 
        	</CRow>  
        	<CRow>
        		<CCol lg="6">
					<label class="mb-1">Client Rate <span class="text-danger">*</span></label>
	        		<CInput 
						placeholder="0.00"
						required
						v-model="bookingParams.client_rate"
						@keypress="isNumber($event)"
						
	                />
				</CCol>
				<CCol lg="2">
					<label class="mb-1">No. of Trips <span class="text-danger">*</span></label>
	        		<CInput
						:disabled="is_roadwise()"
						v-model="bookingParams.no_of_trips"
						required
	                />
				</CCol>
        	
        		
				<CCol lg="3">
	        		<div class="form-group"> 
						
						<div class="mt-0"> 
							<strong style="font-size:1p4x">Total Amount </strong>
								<i class="fa fa-question-circle ml-1" title="Total Amount = Client Rate x No. of Trips" ></i>
								<br>
								<b style="font-size:22px">{{bookingParams.client_rate * bookingParams.no_of_trips}}</b> 
						</div> 
					</div>
				</CCol>
				<CCol lg="12" v-if="details.is_subcon">
					<label class="mb-1">Subcon Rate</label>
	        		<CInput
						placeholder="0.00"
						v-model="bookingParams.subcon_rate" 
						@keypress="isNumber($event)"
	                />
				</CCol>
			
				<CCol lg="6" v-else></CCol>
			</CRow>
		 </fieldset>
				<Expenses :bookingParams="bookingParams" :user_details="user_details" :error_list="error_list"/>
        	
        	<!-- <hr/> -->
		<fieldset class="fieldset full-line">
						<legend v-if="details.driver || details.helper_a || details.helper_b" >Personnel Fee</legend>
		<div>
        	

	        	<div v-if="details.driver">
	        		<CRow class="mb-2">
		        		<CCol lg="12">
							<label class="mb-1">Driver</label>
			        		<CInput
								
								v-model="details.driver.name"
								disabled
			                />
						</CCol> 
		        	</CRow>
		        	<CRow class="mb-2">
		        		<CCol lg="6">
							<label class="mb-1">Driver Rate</label>
			        		<CInput
								@focus="$event.target.select()"
								:class="{'has-error' : error_list['driver-rate']}"
								placeholder="0.00"
								v-model="bookingParams.driver_rate" 
								 @keypress="isNumber($event)"
			                />
							<small v-if="error_list['driver-rate']" class="text-danger" style="font-size:10px">{{ error_list['driver-rate'] }}</small>
						</CCol> 
						<CCol lg="6">
							<label class="mb-1">Driver Allowance</label>
			        		<CInput
							  @focus="$event.target.select()"
                    			:class="{'has-error' : error_list['driver-allowance']}"
								placeholder="0.00"
								v-model="bookingParams.driver_allowance" 
								 @keypress="isNumber($event)"
			                />
							<small v-if="error_list['driver-allowance']" class="text-danger" style="font-size:10px">{{ error_list['driver-allowance'] }}</small>
						</CCol> 
		        	</CRow>
	        	</div> 
	        <div>
				<div v-if="details.helper_a">

	        		<CRow>
		        		<CCol lg="12">
							<label class="mb-1">Helper 1</label>
			        		<CInput
								:value="details.helper_a ? details.helper_a.name : ''"
								disabled
			                />
						</CCol> 
		        	</CRow>
		        	<CRow>
		        		<CCol lg="6">
							<label class="mb-1">Helper 1 Rate</label>
			        		<CInput
								:class="{'has-error' : error_list['helper1-rate']}"
								@focus="$event.target.select()"
								placeholder="00.00"
								v-model="bookingParams.helper_a_rate" 
								  @keypress="isNumber($event)"
			                />
							<small v-if="error_list['helper1-rate']" class="text-danger" style="font-size:10px">{{ error_list['helper1-rate'] }}</small>
						</CCol> 
						<CCol lg="6">
							<label class="mb-1">Helper 1 Allowance</label>
			        		<CInput
							@focus="$event.target.select()"
                    		:class="{'has-error' : error_list['helper1-allowance']}"
								placeholder="0.00"
								v-model="bookingParams.helper_a_allowance" 
								  @keypress="isNumber($event)"
			                />
							<small v-if="error_list['helper1-allowance']" class="text-danger" style="font-size:10px">{{ error_list['helper1-allowance'] }}</small>
						</CCol> 
		        	</CRow>
					</div>
					<div v-if="details.helper_b">
						<CRow>
							<CCol lg="12">
								<label class="mb-1">Helper 2</label>
								<CInput
									:value="details.helper_b ? details.helper_b.name : ''"
									disabled
								/>
							</CCol> 
						</CRow>
						<CRow>
							<CCol lg="6">
								<label class="mb-1">Helper 2 Rate</label>
								<CInput
									:class="{'has-error' : error_list['helper2-rate']}"
									@focus="$event.target.select()"
									placeholder="0.00"
									v-model="bookingParams.helper_b_rate" 
									@keypress="isNumber($event)"
			                />
							<small v-if="error_list['helper2-rate']" class="text-danger" style="font-size:10px">{{ error_list['helper2-rate'] }}</small>
						</CCol> 
						<CCol lg="6">
							<label class="mb-1">Helper 2 Allowance</label>
			        		<CInput
							@focus="$event.target.select()"
                    		:class="{'has-error' : error_list['helper2-allowance']}"
								
								placeholder="0.00"
								v-model="bookingParams.helper_b_allowance" 
								@keypress="isNumber($event)"
			                />
							<small v-if="error_list['helper2-allowance']" class="text-danger" style="font-size:10px">{{ error_list['helper2-allowance'] }}</small>
						</CCol> 
		        	</CRow>
					</div>
					
	        </div>  
	        	
    	</div>
		</fieldset>
			
        </form>
		
		
      </div>
      <div class="modal-footer">
		<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 " @click="cancel">
                Cancel
            </CButton>  
        <CButton size="sm" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 " type="submit" v-if="!hasError" @click="approve">
                Submit
            </CButton> 
			<CButton size="sm" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 " v-else disabled>
                Confirm
            </CButton> 
    		
    		
      </div>
	  </form>
    </div>
  </div>
</div>

	<!-- <CModal title="UPDATE STATUS TO COMPLETED" :show.sync="modalState" color="info" size="lg"> 
		
    	<div slot="footer" class="w-100"></div> 
    </CModal> -->
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datetime from 'vuejs-datetimepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	import '../../style.css';
	import moment from 'moment';
	import Validation from '../validation-handler-mixin.js';
	import Expenses from './includes/expenses';
	import _ from 'lodash'

	function dateFormat() {
		return 'MM/DD/YYYY';
	}
	function dateTimeFormat() {
		return 'MM/DD/YYYY H:i'
	}

	export default{
		mixins: [Validation],
		mounted(){
			console.log(this.error_list, 'MOIN')

			this.modal = document.getElementById('complete-modal')
      
			this.modal.addEventListener('show.bs.modal', () => {
				console.log('show instance method called!');
				// // this.getDestination();
				// this.getCustomer();
				// this.getDepot();
				// this.getBookingType();
				// this.getCommodityType();
				// // this.getVehicle();
				// this.getVehicleType();
				// this.getOrigin();
				// // this.getDestination();
			})
			
			
		}, 
		computed: {
			hasError() {
				if(!this.details?.helper_a) {
					delete this.error_list['helper1-rate'];
					delete this.error_list['helper1-allowance']
				}
				if(!this.details?.helper_b) {
					delete this.error_list['helper2-rate'];
					delete this.error_list['helper2-allowance']
				}
				return Object.keys(this.error_list).length;
			}
		},
		props: {
			showModal: false
		},
		components: {Datetime, vSelect,Expenses},
		data(){
			return{
				modal: null,
				user_details: JSON.parse(window.localStorage.getItem('user_data')),
				company_type: JSON.parse(window.localStorage.getItem('user_data')).company.company_type,
				dataParams: {
					delivery_datetime: "",
					received_datetime: "",
					booking_id: "",
					destination_id: ""
				},
				temp: {
					odometer: ""
				},
				bookingParams: { 
					completed_datetime:  moment().format('MM/DD/YYYY HH:mm'),
					customer_id: "",
					remarks: "",
					client_ref_no: "",
					destination_id: "", 
					origin_id: "",
					depot_id: "",
					booking_type_id: "",
					commodity_id: "",
					vehicle_type_id: "",
					vehicle_id: "",
					client_rate: "",
					no_of_trips: 0,
					subcon_rate: "",
					driver_rate: "",
					driver_allowance: "",
					helper_a_rate: "",
					helper_a_allowance: "",
					helper_b_rate: "",
					helper_b_allowance: "",
					billable: {
						parking_fee: "",
						toll_fee: "",
						other_expenses: "",
						extra_drop: "",
						extra_helper: "",
					},
					vehicle_info : {},
					destination_label: ""
				},
				destinationList: {
					data: []
				},
				customerList: {
					data: []
				},
				bookingTypeList: {
					data: []
				},
				depotList: {
					data: []
				},
				commodityTypeList: {
					data: []
				},
				vehicleList: {
					data: []
				},
				originList: {
					data: []
				},
				destinationList: {
					data: []
				},
				vehicleTypeList: {
					data: []
				},
				modalState: false,
				moment,
				stop_watching: false,
				details:null,
				options:[],
				cancelTokenSource: null,
				selected_destination_id: null
			}
		},
		watch: {
			details: {
				deep: true,
				async handler(){
					console.log(this.details, 'WATCH')
					if(this.details.billable){ 
						this.bookingParams.billable = this.details.billable;
					}
					
					this.bookingParams.remarks = this.details.remarks; 
					this.bookingParams.client_ref_no = this.details.client_ref_no;
					this.bookingParams.destination_id = this.details.destination_id;
					this.bookingParams.origin_id = this.details.origin_id;
					this.bookingParams.customer_id = this.details.customer_id;
					this.bookingParams.depot_id = this.details.depot_id;
					this.bookingParams.booking_type_id = this.details.booking_type_id; 
					this.bookingParams.commodity_id = this.details.commodity_id;  
					this.bookingParams.vehicle_id = this.details.vehicle_id;  
					this.bookingParams.no_of_trips = this.details.no_of_trips;   
					this.bookingParams.billable.extra_drop = this.details.billable.extra_drop;
					this.bookingParams.billable.extra_helper = this.details.billable.extra_helper;
					this.bookingParams.vehicle_type_id = this.details.vehicle_type_id;
					this.bookingParams.vehicle_info = this.details.vehicle_info;
					if(this.bookingParams.no_of_trips == 0){
						this.bookingParams.no_of_trips = 1
					}

					this.selected_destination_id = this.details.destination_id;

					this.$set(this.bookingParams,'vehicle_type_label',this.details?.vehicle_type?.setting_name);
					this.$set(this.bookingParams,'destination_label', this.details?.destination?.setting_name);

					// this.getDestination();
					await this.getCustomer();
					await this.getDepot();
					await this.getBookingType();
					await this.getCommodityType();
					// this.getVehicle();
					await this.getVehicleType();
					await this.getOrigin();
					// this.getDestination();
					

					await this.getClientRate(this.details.vehicle_type_id);
					await this.getPersonnelRate()
					await this.getVehicle()
					if(this.details.id != ""){
						await this.getDestination();
					}
				}
			}, 
			showModal: {
				handler(){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					} 
				}
			}
		},
		methods: {
			onClear(ev) {
				console.log(this.bookingParams.destination_label, this.bookingParams.destination_id)
			},
			vehicleChange(e){
				console.log('E', e)
				if(!e) {
					this.bookingParams.vehicle_id = null;
					this.bookingParams.vehicle_info = null;
					return;
				}

				this.bookingParams.vehicle_id = e.id;
				this.bookingParams.vehicle_info = e;

				this.stop_watching = true;
				
				// var vehicle = this.vehicleList.data.filter((value, index)=>{
				// 	return value.id == this.bookingParams.vehicle_id;
				// })

				// if(vehicle.length) {
				// 	if(this.bookingParams.vehicle_id != ""){ 
				// 		this.$set(this.bookingParams,'vehicle_type_id',vehicle[0].vehicle_type_id) 
				// 	}

				// 	var vendor = this.vendorList?.data.filter((value, index)=>{
				// 		return vehicle[0].vendor_id == value.id;
				// 	})
				// 	if(vendor)
				// 		this.$set(this.bookingParams,'vendor_id',vehicle[0].vendor_id)
				// 	else  
				// 		this.$set(this.bookingParams,'vendor_id',null)
				// }	
			}, 
			// vehicleChange(val) {
			// 	var vehicle = this.vehicleList.data.filter((value, index)=>{
			// 		return value.id == val;
			// 	})

			// 	if(vehicle.length) {

			// 		this.$set(this.bookingParams,'vehicle_type_id',vehicle[0].vehicle_type_id)
			// 		this.$set(this.bookingParams,'vehicle_type_label',vehicle[0]?.vehicle_type)

	
			// 	}
			// 	else {
			// 		this.$set(this.bookingParams,'vehicle_type_id', null)
			// 		this.$set(this.bookingParams,'vehicle_type_label', null)

			// 	}
			// },
			destinationChanged(value) {
				this.bookingParams.setting_name = value.setting_name;
				this.bookingParams.destination_id = value.destination_id;
				this.selected_destination_id = value.destination_id;
				this.getClientRate(this.bookingParams.vehicle_type_id)
			},

			
			computeclientrate(value) {
				this.bookingParams.destination_id = '';
				this.getDestination()
				this.getClientRate()
			},
			
			truckTypeChange(value) {
				console.log(value)
				this.bookingParams.vehicle_type_id = value?.id ?? 0;
				this.$set(this.bookingParams,'vehicle_type_label',value?.setting_name?.trim() ?? "");
				this.bookingParams.destination_id = ''
				this.getDestination()
				this.getClientRate(this.bookingParams.vehicle_type_id)
			},
			getClientRate(v_type_id = null){
				// console.log('asd',this.bookingParams.vehicle_id)
				const vm = this;
				let vehicle_type_id = '';
				if(!v_type_id) {
					vehicle_type_id = this.vehicleList.data.filter((value, index)=>{
						return value.id == this.bookingParams.vehicle_id;
					}); 
					if(vehicle_type_id.length > 0){
						vehicle_type_id = vehicle_type_id[0].vehicle_type_id
					}
					else{
						vehicle_type_id = ""
					}
				}
				else vehicle_type_id = v_type_id;

				console.log(this.bookingParams, 'GET CLIENT RATE')
				
				this.$showLoading(true);
				const destination_id = vm.selected_destination_id ? vm.selected_destination_id : this.bookingParams.destination_id;
				const params = {
					commodity_id: vm.bookingParams.commodity_id,
					depot_id: vm.bookingParams.depot_id,
					vehicle_type_id: vm.bookingParams.vehicle_type_id,
					customer_id: vm.bookingParams.customer_id,
					origin_id : vm.bookingParams.origin_id,
					destination_id
				}

				    
				axios.get(`${config.api_path}/reference/furthest-destination`, {
					params
				}).then(response => {
					const {data} = response.data;

					if(data && data.length) {

						if(destination_id) {
							vm.options = data.map((value, index) => {
								value.setting_name = value.destination.setting_name;
								return value
							});
						}
						 
						if(destination_id){
							// this.bookingParams.client_rate = data[0].client_rate;
							// this.bookingParams.subcon_rate = data[0].subcon_rate;
							this.$set(this.bookingParams,'client_rate', data[0].client_rate);
							this.$set(this.bookingParams,'subcon_rate', data[0].subcon_rate);
						}
						else {
							// this.bookingParams.client_rate = 0;
							// this.bookingParams.subcon_rate = 0;
							this.$set(this.bookingParams,'client_rate', 0);
							this.$set(this.bookingParams,'subcon_rate', 0);
						}

						
					}
					else {
						this.$set(this.bookingParams,'client_rate', 0);
						this.$set(this.bookingParams,'subcon_rate', 0);
					}
					
					
					this.$showLoading(false);
				}).catch(err => {
					console.error('CLIENT RATE - ',err)
					this.$showLoading(false);
				})
				
				// axios.get(config.api_path+'/client_rate?commodity_id='+this.bookingParams.commodity_id
				// +'&depot_id='+this.bookingParams.depot_id
				// +'&vehicle_type_id='+vehicle_type_id
				// +'&customer_id='+this.bookingParams.customer_id
				// +'&origin_id='+this.bookingParams.origin_id
				// +'&destination_id='+this.bookingParams.destination_id
				// +'&page=1&limit=10000000')
    			// .then(response => {
				// 	const {data} = response.data;

				// 	if(response.data.data.length > 0 && this.bookingParams.destination_id){
    			// 		this.bookingParams.client_rate = response.data.data[0].client_rate;
    			// 		this.bookingParams.subcon_rate = response.data.data[0].subcon_rate;
    			// 	}
				// 	else {
				// 		this.bookingParams.client_rate = 0;
    			// 		this.bookingParams.subcon_rate = 0;
				// 	}

				// 	this.$showLoading(false);
    			// })
			},

			getPersonnelRate(){
				axios.get(config.api_path+'/personnel_rate?depot_id='+this.bookingParams.depot_id+'&customer_id='+this.bookingParams.customer_id+'&page=1&limit=20&page=1&limit=10000000')
    			.then(response => {
    				if(response.data.data.length > 0){
    					// this.bookingParams.client_rate = response.data.data[0].client_rate;
    					// this.bookingParams.subcon_rate = response.data.data[0].subcon_rate;
						//FOR MARVEL ACCOUNT DRIVER AND HELPER RATES
						
						if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
							this.bookingParams.driver_rate = (this.details.driver_rate) ? this.details.driver_rate : this.details.driver.daily_rate;
							this.bookingParams.helper_a_rate = (this.details.helper_a_rate) ? this.details.helper_a_rate : this.details.helper_a.daily_rate;
							this.bookingParams.helper_b_rate = (this.details.helper_b_rate) ? this.details.helper_b_rate : this.details.helper_b.daily_rate;
						}
						else {
							this.bookingParams.driver_rate = response.data.data[0].delivery_rate;
							this.bookingParams.helper_a_rate = response.data.data[0].helper_rate;
    						this.bookingParams.helper_b_rate = response.data.data[0].helper_rate;
						}
    					
    					this.bookingParams.driver_allowance = response.data.data[0].driver_allowance;
    					this.bookingParams.helper_a_allowance = response.data.data[0].helper_allowance;
    					this.bookingParams.helper_b_allowance = response.data.data[0].helper_allowance;

    				}
					else {
						if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
							this.bookingParams.driver_rate = (this.details.driver_rate) ? this.details.driver_rate : this.details.driver.daily_rate;
							this.bookingParams.helper_a_rate = (this.details.helper_a_rate) ? this.details.helper_a_rate : this.details.helper_a.daily_rate;
							this.bookingParams.helper_b_rate = (this.details.helper_b_rate) ? this.details.helper_b_rate : this.details.helper_b.daily_rate;
						}
					}
    			})
			},

			approve(){
				
				
				if(this.bookingParams.client_rate == ""){
					this.bookingParams.client_rate = 0
				}
				if(this.bookingParams.subcon_rate == ""){
					this.bookingParams.subcon_rate = 0
				}
				if(this.bookingParams.driver_rate == ""){
					this.bookingParams.driver_rate = null;
				}
				if(this.bookingParams.driver_allowance == ""){
					this.bookingParams.driver_allowance = null;
				}
				if(this.bookingParams.helper_a_rate == ""){
					this.bookingParams.helper_a_rate = null;
				}
				if(this.bookingParams.helper_a_allowance == ""){
					this.bookingParams.helper_a_allowance = null;
				}
				if(this.bookingParams.helper_b_rate == ""){
					this.bookingParams.helper_b_rate = null;
				}
				if(this.bookingParams.helper_b_allowance == ""){
					this.bookingParams.helper_b_allowance = null;
				}

				if(this.bookingParams.billable.toll_fee == ""){
		    		this.bookingParams.billable.toll_fee = 0;
		    	} 
		    	if(this.bookingParams.billable.parking_fee == ""){
		    		this.bookingParams.billable.parking_fee = 0;
		    	} 
		    	if(this.bookingParams.billable.other_expenses == ""){
		    		this.bookingParams.billable.other_expenses = 0;
		    	} 

		    	if(this.bookingParams.billable.extra_drop == ""){
		    		this.bookingParams.billable.extra_drop = 0;
		    	} 
		    	if(this.bookingParams.billable.extra_helper == ""){
		    		this.bookingParams.billable.extra_helper = 0;
		    	} 
				this.bookingParams.total_amount = this.bookingParams.client_rate * this.bookingParams.no_of_trips
				this.bookingParams.status = "completed"

				if(this.details.is_subcon != 1) {
					this.bookingParams.subcon_rate = null;
				}

				console.log(this.bookingParams);
				// axios.post(config.api_path+'/bookingStatusDetail', this.dataParams)
				// .then(response=>{ 

					// this.bookingParams.status = "completed";
					// axios.put(config.api_path+'/booking/'+this.details.id, this.bookingParams)
					Swal.fire({
					 title: 'Are you sure to tag this as COMPLETED?',
						text: "This action cannot be undone.",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						reverseButtons:true,
						confirmButtonText: 'Yes, confirm'
						}).then((result) => {
							if (result.isConfirmed) {
								this.bookingParams.completed_datetime = moment(this.bookingParams.completed_datetime, dateTimeFormat()).valueOf() / 1000; 
								this.bookingParams.user_id = config.getUserDetail('id');
								this.bookingParams.status_update = true;
								this.$showLoading(true)
								axios.put(config.api_path+'/booking/'+this.details.id, this.bookingParams).then(res => {
									this.$showLoading(false)
									Swal.fire(
									'Completed!',
									'Booking successfully updated to completed.',
									'success'
									);
									this.$emit('dismissModal', 'complete')
								});
							}
						})
					// .then(response=>{
					// 	this.$emit('dismissModal', 'complete')
					// })

				// })
				
			},
			cancel(){ 
				this.$emit('dismissModal', 'complete') 
			},
			// getDestination(){
			// 		axios.get(config.api_path+'/setting?setting_type=destination&page=1&limit=1000000')
		    // 	// axios.get(config.api_path+'/setting?setting_type=destination&page=1&limit=10000000')
		    // 	.then(response => {
		    // 		this.destinationList = response.data; 
		    // 	})

		    // },
		    getDepot(){

		    	axios.get(config.api_path+'/reference/settings-list',{
				params:{
				setting_type:'depot'
				}
       		   })
		    	.then(response => {
		    		this.depotList = response.data; 
		    	})

	    	},
			getBookingType(){
				axios.get(config.api_path+'/reference/settings-list',{
				params:{
				setting_type:'booking_type'
				}
       		   })
	    	.then(response => {
	    		this.bookingTypeList = response.data; 
	    	})

		    },
			getVehicleType(){
				this.isLoading = true;
				axios.get(config.api_path+'/reference/settings-list',{
				params:{
				setting_type:'vehicle_type'
				}
       		   })
	    	.then(response => {
	    		this.vehicleTypeList = response.data; 
	    	})

				.finally(fin => {
					this.isLoading = false;
				})

			},

		    getCustomer(){

		    	axios.get(config.api_path+'/reference/customer-list',{
				params:{
					show_all : true
				}
			})
	    	.then(response => {
	    		this.customerList = response.data;
			})

		    },

		    getCommodityType(){

		    	axios.get(config.api_path+'/reference/settings-list',{
				params:{
				setting_type:'commodity_type'
				}
       		   })
		    	.then(response => {
		    		this.commodityTypeList = response.data; 
		    	})

		    },

		    getVehicle(){

				axios.get(config.api_path+'/reference/vehicle-no',{
					params: {
						is_subcon : this.details.is_subcon ? 1 : 0,
						vendor_id : null
					}
				}).then(res => {
					const {data , status} = res.data;
					if(status == 'success') {
						this.vehicleList.data = data;
					}
				})
		    },

		    getOrigin(){

		    	axios.get(config.api_path+'/reference/settings-list',{
				params:{
				setting_type:'origin'
				}
       		   })
		    	.then(response => {
		    		this.originList = response.data; 
		    	})

		    },

		    getDestination(){
				return; // method not used
				console.log(this.bookingParams, 'GET DESTINATION')
				let customer_id = this.bookingParams.customer_id;
				
		    	if(this.company_type.toLowerCase() == "trucking with agency"){
					axios.get(config.api_path+'/client_rate?commodity_id='+this.bookingParams.commodity_id
					+'&depot_id='+this.bookingParams.depot_id
					+'&vehicle_type_id='+this.bookingParams.vehicle_type_id
					+'&customer_id='+customer_id
					+'&origin_id='+this.bookingParams.origin_id
					+'&page=1&limit=10000000')
					.then(response => {
						this.destinationList = response.data;
						this.destinationList.data = this.destinationList.data.map((value, index) => {
							value.setting_name = value.destination.setting_name;
							return value
						}); 
					}).catch(err => {
						console.error(err)
					})
				}
				else{
					axios.get(config.api_path+'/reference/settings-list',{
						params:{
						setting_type:'destination'
						}
							})
						.then(response => {
							this.destinationList = response.data; 
							this.destinationList.data = this.destinationList.data.map((value, index) => {
			    			value.destination_id = value.id
			    			return value;
						}); 
							
						})
					}
				},
			
			onSearch(search, loading) {
				
				if(search.length > 2) {
					let vm = this;
					loading(true);
					this.search(loading, search, this);
				}
			},
			search: _.debounce((loading, search, vm) => {
				const params = {
					commodity_id: vm.details.commodity_id,
					depot_id: vm.details.depot_id,
					vehicle_type_id: vm.details.vehicle_type_id,
					customer_id: vm.details.customer_id,
					origin_id : vm.details.origin_id,
					qry: search
				}

				if(vm.cancelTokenSource) {
					vm.cancelTokenSource.cancel();
					vm.cancelTokenSource = null;
				}

				vm.cancelTokenSource = axios.CancelToken.source();

				if(vm.company_type.toLowerCase() == "trucking with agency"){      
					axios.get(`${config.api_path}/reference/furthest-destination`, {
						cancelToken: vm.cancelTokenSource.token,
						params
					}).then(response => {
						const d = response.data;
						vm.options = d.data.map((value, index) => {
							value.setting_name = value.destination.setting_name;
							return value
						}); 

						loading(false);
					}).catch(err => {
						console.error('DESTINATION SEARCH - ',err)
						loading(false);
					})
				}
				else {
					axios.get(config.api_path+'/reference/settings-list',{
							params: {
								setting_type:'destination',
								qry: search
							}
						})
						.then(response => {
							const d = response.data;
							vm.options = d.data.map((value, index) => {
								value.destination_id = value.id;
								value.destination = value.setting_name
								return value;
							}); 

							loading(false);
						}).catch(err => {
							loading(false);
							console.error(err)
						})
				}

			}, 350),

		}

	}
</script>
<style scoped>
.btn:focus,.btn:active {
   outline: none !important;
   box-shadow: none;
}
</style>